<template>
    <div class="rb-view">
        <el-form :model="form_data" :ref="form_name" :rules="form_rules" size="mini" label-width="200px" style="max-width: 800px" inline>
            <el-row>
                <el-col>
                    <el-form-item label="邀请好友分享标题" prop="invite_share_msg_title" required>
                        <el-input v-model="form_data.invite_share_msg_title"></el-input>
                        <div class="rb-input-tip">通过微信消息分享给好友的消息标题</div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="邀请好友分享小程序落地页" prop="invite_share_msg_path" required>
                        <el-input v-model="form_data.invite_share_msg_path"></el-input>
                        <div class="rb-input-tip">通过微信消息分享跳转到的小程序落地页</div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="邀请好友消息分享海报" prop="invite_share_msg_cover" required>
<!--                        <el-input-number v-model="form_data.invite_share_msg_cover" :min="0"></el-input-number>-->
                        <el-upload
                                class="avatar-uploader"
                                :action="uploadUrl"
                                :headers="uploadHeader"
                                :show-file-list="false"
                                :limit="1"
                                :on-preview="handleInviteCoverPreview"
                                :on-remove="handleInviteCoverRemove"
                                :on-success="onInviteCoverUploadSuccess"
                                :before-upload="beforeInviteCoverFileUpload"
                        >
                            <div style="width:80px;height:80px">
                                <img style="width:100%;" v-if="form_data.invite_share_msg_cover" :src="imgHost+form_data.invite_share_msg_cover" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </div>
                        </el-upload>
                        <div class="rb-input-tip">通过微信消息分享给好友的封面页</div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="邀请好友获得优惠券ID" prop="invite_share_coupon_id" required>
                        <div style="display:flex;flex-direction:row;align-items: center;">
                            <el-button type="text" @click="handleOnSelectCoupon('invite_share_coupon_id')">选择优惠券</el-button>
                            <div style="margin-left: 15px;font-size:12px;display:flex;flex-direction:row;align-items:center;" v-if="form_data.invite_share_coupon_id && form_data.invite_share_coupon_id>0">
                                已选优惠券 ID:<span style="font-weight: 600;">{{form_data.invite_share_coupon_id}}</span>
                                <router-link target="_blank" :to="'/market/coupon-detail?action=view&id='+form_data.invite_share_coupon_id" style="color:#409EFF;margin-left:15px;font-size:12px">查看优惠券</router-link>
                                <el-button type="text" @click="handleOnClearCouponId('invite_share_coupon_id')" style="margin-left:15px;">清除</el-button>
                            </div>
                        </div>
                        <div class="rb-input-tip">清除优惠券/发送数量为0,则不发送</div>
                        <div v-if="form_data.invite_share_coupon_id && invite_share_coupon && invite_share_coupon.id" style="margin-top:15px;">
                            <div style="position:relative;background: linear-gradient(0deg, rgb(254, 98, 99), rgb(254, 49, 78));min-height:138px;">
                                <div style="height:60px;padding:0 10px;display:flex;flex-direction:row;justify-content:center;align-items:center;">
                                    <div style="color:#FFFFFF;font-weight: 600;font-size:30px;">{{invite_share_coupon.coupon_type_name}}</div>
                                </div>
                                <div style="display:flex;flex-direction:column;align-items:center;color:#FFFFFF">
                                    <div>{{invite_share_coupon.coupon_name}}</div>
                                    <div>{{invite_share_coupon.remark}}</div>
                                </div>
                                <div style="background-image: radial-gradient(rgb(249, 249, 249) 0px, rgb(249, 249, 249) 5px, rgb(254, 98, 99) 5px, rgb(254, 98, 99) 100%);height:7px;background-size: 15px 15px;width:100%;position:absolute;bottom:0;"></div>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col>
                    <el-form-item label="新用户可获优惠券ID" prop="new_user_coupon_id" required>
                        <div style="display:flex;flex-direction:row;align-items: center;">
                            <el-button type="text" @click="handleOnSelectCoupon('new_user_coupon_id')">选择优惠券</el-button>
                            <div style="margin-left: 15px;font-size:12px;display:flex;flex-direction:row;align-items:center;" v-if="form_data.new_user_coupon_id && form_data.new_user_coupon_id>0">
                                已选优惠券 ID:<span style="font-weight: 600;">{{form_data.new_user_coupon_id}}</span>
                                <router-link target="_blank" :to="'/market/coupon-detail?action=view&id='+form_data.new_user_coupon_id" style="color:#409EFF;margin-left:15px;font-size:12px">查看优惠券</router-link>
                                <el-button type="text" @click="handleOnClearCouponId('new_user_coupon_id')" style="margin-left:15px;">清除</el-button>
                            </div>
                        </div>
                        <div class="rb-input-tip">清除优惠券/发送数量为0,则不发送</div>
                        <div v-if="form_data.new_user_coupon_id && new_user_coupon && new_user_coupon.id" style="margin-top:15px;">
                            <div style="position:relative;background: linear-gradient(0deg, rgb(254, 98, 99), rgb(254, 49, 78));min-height:138px;">
                                <div style="height:60px;padding:0 10px;display:flex;flex-direction:row;justify-content:center;align-items:center;">
                                    <div style="color:#FFFFFF;font-weight: 600;font-size:30px;">{{new_user_coupon.coupon_type_name}}</div>
                                </div>
                                <div style="display:flex;flex-direction:column;align-items:center;color:#FFFFFF">
                                    <div>{{new_user_coupon.coupon_name}}</div>
                                    <div>{{new_user_coupon.remark}}</div>
                                </div>
                                <div style="background-image: radial-gradient(rgb(249, 249, 249) 0px, rgb(249, 249, 249) 5px, rgb(254, 98, 99) 5px, rgb(254, 98, 99) 100%);height:7px;background-size: 15px 15px;width:100%;position:absolute;bottom:0;"></div>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>

            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="新用户可获优惠券ID_2" prop="new_user_coupon_id_2" required>
                        <div style="display:flex;flex-direction:row;align-items: center;">
                            <el-button type="text" @click="handleOnSelectCoupon('new_user_coupon_id_2')">选择优惠券</el-button>
                            <div style="margin-left: 15px;font-size:12px;display:flex;flex-direction:row;align-items:center;" v-if="form_data.new_user_coupon_id_2 && form_data.new_user_coupon_id_2>0">
                                已选优惠券 ID:<span style="font-weight: 600;">{{form_data.new_user_coupon_id_2}}</span>
                                <router-link target="_blank" :to="'/market/coupon-detail?action=view&id='+form_data.new_user_coupon_id_2" style="color:#409EFF;margin-left:15px;font-size:12px">查看优惠券</router-link>
                                <el-button type="text" @click="handleOnClearCouponId('new_user_coupon_id_2')" style="margin-left:15px;">清除</el-button>
                            </div>
                        </div>
                        <div class="rb-input-tip">清除优惠券/发送数量为0,则不发送</div>
                        <div v-if="form_data.new_user_coupon_id_2 && new_user_coupon_2 && new_user_coupon_2.id" style="margin-top:15px;">
                            <div style="position:relative;background: linear-gradient(0deg, rgb(254, 98, 99), rgb(254, 49, 78));min-height:138px;">
                                <div style="height:60px;padding:0 10px;display:flex;flex-direction:row;justify-content:center;align-items:center;">
                                    <div style="color:#FFFFFF;font-weight: 600;font-size:30px;">{{new_user_coupon_2.coupon_type_name}}</div>
                                </div>
                                <div style="display:flex;flex-direction:column;align-items:center;color:#FFFFFF">
                                    <div>{{new_user_coupon_2.coupon_name}}</div>
                                    <div>{{new_user_coupon_2.remark}}</div>
                                </div>
                                <div style="background-image: radial-gradient(rgb(249, 249, 249) 0px, rgb(249, 249, 249) 5px, rgb(254, 98, 99) 5px, rgb(254, 98, 99) 100%);height:7px;background-size: 15px 15px;width:100%;position:absolute;bottom:0;"></div>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>

            </el-row>

            <el-row>
                <el-col>
                    <el-form-item label="散/包场预约分享小程序消息标题" prop="order_play_share_msg_title" required>
                        <el-input v-model="form_data.order_play_share_msg_title"></el-input>
                        <div class="rb-input-tip">通过微信消息分享给好友的消息标题</div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="散/包场预约邀请好友分享小程序落地页" prop="order_play_share_msg_path" required>
                        <el-input v-model="form_data.order_play_share_msg_path"></el-input>
                        <div class="rb-input-tip">通过微信消息分享跳转到的小程序落地页</div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="散/包场预约分享小程序消息海报" prop="order_play_share_msg_cover" required>
                        <!--                        <el-input-number v-model="form_data.invite_share_msg_cover" :min="0"></el-input-number>-->
                        <el-upload
                                class="avatar-uploader"
                                :action="uploadUrl"
                                :headers="uploadHeader"
                                :show-file-list="false"
                                :limit="1"
                                :on-preview="handlePlaySingleCoverPreview"
                                :on-remove="handlePlaySingleCoverRemove"
                                :on-success="onPlaySingleCoverUploadSuccess"
                                :before-upload="beforePlaySingleCoverFileUpload"
                        >
                            <div style="width:80px;height:80px">
                                <img style="width:100%;" v-if="form_data.order_play_share_msg_cover" :src="imgHost+form_data.order_play_share_msg_cover" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </div>
                        </el-upload>
                        <div class="rb-input-tip">通过微信消息分享给好友的封面页</div>
                    </el-form-item>
                </el-col>
            </el-row>


            <div style="display:flex;flex-direction:row;justify-content:flex-end;width:100%;">
                <div style="float:right;">
                    <el-button @click="handleSubmitTap(form_name)" size="mini" type="primary">保存</el-button>
                </div>
            </div>

        </el-form>

        <el-dialog v-model="dialog_coupon_select_list_visible" :title="dialog_coupon_select_list_title" @close="onTellSelectCouponDialogClose" width="70%" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <coupon-select-list :field="dialog_coupon_select_field" mode="single" @onTellDialogClose="onTellSelectCouponDialogClose" @onCouponSelectConfirm="onCouponSelectConfirm"></coupon-select-list>
        </el-dialog>
    </div>
</template>

<script>
    import { getWeAppSetting,  setWeAppSetting} from "@/api/hado/setting";
    import {getDetail as getCouponDetail} from "@/api/hado/coupon";
    import CouponSelectList from '@/views/b/market/coupon-select-list'
    import {getToken} from "@/utils/auth";
    export default {
        name: "we-app-setting",
        components:{CouponSelectList},
        data(){
            return{
                form_name:'we_app_setting_form',
                form_data:{},
                form_rules:{
                    invite_share_msg_cover:[{required:true,message:'请设置分享海报页',trigger:'change'}],
                },
                invite_share_coupon:undefined,
                new_user_coupon:undefined,
                new_user_coupon_2:undefined,

                imgHost:process.env.VUE_APP_BASE_API,
                uploadUrl:process.env.VUE_APP_BASE_API+'/api/sys/file/upload',
                uploadHeader:{},

                dialog_coupon_select_list_visible:false,
                dialog_coupon_select_list_title:'请选择要派发的优惠券',
                dialog_coupon_select_field:'',

            }
        },
        mounted() {
            this.uploadHeader['Authorization'] = 'Bearer ' + getToken();
        },
        created(){
            this.initPage()
        },
        methods:{
            initPage(){
                getWeAppSetting().then(resp=>{
                    console.log('resp:',resp)
                    this.form_data = resp.data

                    //这里如果设置了优惠券ID的 再获取一下优惠券ID
                    if(resp.data.invite_share_coupon_id&&resp.data.invite_share_coupon_id>0) {
                        getCouponDetail(resp.data.invite_share_coupon_id).then(resp2=>{
                            this.invite_share_coupon = resp2.data;
                        })
                    }
                    if(resp.data.new_user_coupon_id&&resp.data.new_user_coupon_id>0) {
                        getCouponDetail(resp.data.new_user_coupon_id).then(resp2=>{
                            this.new_user_coupon = resp2.data;
                        })
                    }
                    if(resp.data.new_user_coupon_id_2&&resp.data.new_user_coupon_id_2>0) {
                        getCouponDetail(resp.data.new_user_coupon_id_2).then(resp2=>{
                            this.new_user_coupon_2 = resp2.data;
                        })
                    }
                })
            },
            reloadPage(){
                this.initPage()
            },
            handleSubmitTap(form_name) {
                this.$refs[form_name].validate((valid)=>{
                    if(valid){
                        setWeAppSetting(this.form_data).then(()=>{
                            this.reloadPage()
                        })
                    }
                })
            },
            handleOnSelectCoupon(field_name){
                console.log('field_name:',field_name)
                this.dialog_coupon_select_field = field_name
                this.dialog_coupon_select_list_visible=true
            },
            handleOnClearCouponId(field_name){
                console.log('field_name:',field_name)
            },
            onTellSelectCouponDialogClose(){
                this.dialog_coupon_select_field = ''
                this.dialog_coupon_select_list_visible=false
            },
            //优惠券选择通知事件
            onCouponSelectConfirm(e){
                console.log('onCouponSelectConfirm:',e)
                //这里先做修改编辑处理 然后再关闭弹窗
                getCouponDetail(e.coupon_id).then(resp=>{
                    console.log('优惠券再次查询返回:',resp)
                    let coupon = resp.data;
                    // this.form_data.group_success_send_coupon_id = coupon.id;
                    this.form_data[e.field] = coupon.id
                    // this.send_coupon = coupon;
                    if(e.field === 'invite_share_coupon_id'){
                        this.invite_share_coupon = coupon
                    }else if(e.field == 'new_user_coupon_id'){
                        this.new_user_coupon = coupon
                    }
                    else if(e.field == 'new_user_coupon_id_2'){
                        this.new_user_coupon_2 = coupon
                    }
                    this.onTellSelectCouponDialogClose()
                })
            },
            //图片处理
            //
            beforeInviteCoverFileUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isPNG = file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG&&!isPNG) {
                    this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return (isJPG||isPNG) && isLt2M;
            },
            handleInviteCoverPreview(file){
                console.log(file)
            },
            handleInviteCoverRemove(file,fileList){
                console.log(file,fileList)
            },
            onInviteCoverUploadSuccess(response, file, fileList){
                console.log(response,file,fileList)
                if(response.code == 200){
                    this.form_data.invite_share_msg_cover = response.data.url;
                    console.log(this.form_data.invite_share_msg_cover)
                    console.log(this.imgHost+this.form_data.invite_share_msg_cover)
                    this.$forceUpdate() //强制i更新
                }
            },
            beforePlaySingleCoverFileUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isPNG = file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG&&!isPNG) {
                    this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return (isJPG||isPNG) && isLt2M;
            },
            handlePlaySingleCoverPreview(file){
                console.log(file)
            },
            handlePlaySingleCoverRemove(file,fileList){
                console.log(file,fileList)
            },
            onPlaySingleCoverUploadSuccess(response, file, fileList){
                console.log(response,file,fileList)
                if(response.code == 200){
                    this.form_data.order_play_share_msg_cover = response.data.url;
                    console.log(this.form_data.order_play_share_msg_cover)
                    console.log(this.imgHost+this.form_data.order_play_share_msg_cover)
                    this.$forceUpdate() //强制i更新
                }
            },
        }
    }
</script>

<style scoped>
    .rb-input-tip{
        color: #969799;
        line-height: 14px;
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 0;
    }
</style>
